import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const FreeOffersPage = () => (

    <Layout pageClass="free-offers-page">
    <SEO title="Free Offers" />
    <main className="main">
        <div className="typography inner">
            <h1>Free Offers</h1>
            <div className="offers-container">
                <div className="offer">
                    <h2>Our FREE Offer to you ($500 value)</h2>

                    <p>It can be difficult to see what you need to do to improve your business. Getting an outside perspective is an invaluable starting point.To help you to understand what might be achievable with our guidance we provide a FREE, NO OBLIGATION, DISCOVERY PROCESS. It involves three steps:</p>
                    <ol>
                        <li>A telephone conversation to establish an initial perspective of your business and to understand your motivation.</li>
                        <li>A visit to your premises to view your company’s operations and to gain a better understanding of your day to day processes.</li>
                        <li> A two hour face to face working session to document
                            <ul>
                                <li>Your aspirations and timetable</li>
                                <li>What steps are likely to be needed to progress from a to b</li>
                                <li>Once we have been through your discovery process we will understand what needs to be done, what it is like to work together and be in a position provide you with a proposal to meet your needs.</li>
                            </ul>
                        </li>
                    </ol>
            
                    <p>Why not do it? All it is going to cost you is your time.</p>

                </div>
                <div className="offer">
                    <h2>Potential Government Assistance  –  NZTE Business Development Voucher Scheme</h2>

                    <p>The government recognises that SMEs are a major factor in the New Zealand economy and has set up this scheme to encourage growth through the development of management capabilities of small business owners and managers. The scheme provides up to a 50% subsidy to a maximum of $5,000 plus GST in a financial year for Business Coaching and Training services from approved suppliers under this scheme.</p>

                    <p>We will advise you how to apply for this excellent scheme. We have worked with a significant number of clients who have benefited from this government encouragement and have achieved some great results.</p>


                </div>
                <div className="offer">
                    <h2>RYLA (Rotary Youth Leadership Awards)</h2>
                    <p>is a week-long leadership development programme for 20-28 year olds hosted and sponsored by Rotary Clubs in the district. RYLA is an experiential live-in programme designed to help young people develop their team work and communication skills and fulfil their potential as leaders.</p>
                    <p>The programme consists of a combination of:</p>
                    <ul>
                        <li>Talks by community and business leaders particularly focusing on leadership development.</li>
                        <li>Opportunities for the exchange of ideas, opinions and experiences in discussion groups etc.</li>
                        <li>Open sessions, sport and keep fit exercises.</li>
                        <li>Involvement in a community activity and with the host Rotary Club.</li>


                    </ul>
            
                    <p>You can sponsor staff, family or friends to participate in this life changing program.</p>

                </div>
               


            </div>
        </div>
    </main>
    </Layout>

)


export default FreeOffersPage